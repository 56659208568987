<template>
    <div>
    </div>
</template>

<script>
    export default {
        name: "demo",
        created() {
            let a  = this.$route.query.id;
            window.open(a)
            this.$router.push({ path: "index" });
        },
    }

</script>

<style scoped>

</style>
